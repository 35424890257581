<script setup lang="ts">
import type { Schemas } from "#shopware";
import { useGtm } from "@gtm-support/vue-gtm";
import { ApiClientError } from "@shopware/api-client";

const props = withDefaults(
  defineProps<{
    cartItem: Schemas["LineItem"];
    maxQty?: number;
  }>(),
  {
    maxQty: 100,
  },
);

const { cartItem } = toRefs(props);

const isLoading = ref(false);
const { getErrorsCodes } = useCartNotification();
const { refreshCart } = useCart();
const { pushError } = useNotifications();
const { t } = useI18n();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { lineItemsToTrackingItems } = await useTrackingHelper();
const gtm = useGtm();

const {
  isProduct,
  removeItem,
  itemQuantity,
  itemRegularPrice,
  itemSpecialPrice,
  itemTotalPrice,
  itemOptions,
  changeItemQuantity,
} = useCartItem(cartItem);

const quantity = ref();
syncRefs(itemQuantity, quantity);

const { resolveApiErrors } = useApiErrorsResolver("account_login");

const productPath = computed(() =>
  formatLink(`/detail/${cartItem.value.referencedId}`),
);

const inputType = ref("text");
const inputFocus = () => (inputType.value = "number");
const inputBlur = () => (inputType.value = "text");

const displayPrice = computed(() => {
  if (itemQuantity.value > 1) return itemTotalPrice.value;
  if (itemSpecialPrice.value) return itemSpecialPrice.value;
  return itemRegularPrice.value;
});

const updateQuantity = async (quantityInput: number | undefined) => {
  if (quantityInput === itemQuantity.value) return;

  isLoading.value = true;

  try {
    const response = await changeItemQuantity(Number(quantityInput));
    // Refresh cart after qty update
    await refreshCart(response);
  } catch (error) {
    if (error instanceof ApiClientError) {
      const errors = resolveApiErrors(error.details.errors);
      errors.forEach((error) => pushError(error));
    }
  }

  // Make sure that qty is the same as it is in the response
  quantity.value = itemQuantity.value;

  getErrorsCodes()?.forEach((element) => {
    pushError(t(`errors.${element.messageKey}`, { ...element }));
  });

  isLoading.value = false;
};
const debounceUpdate = useDebounceFn(updateQuantity, 800);

watch(quantity, () => debounceUpdate(quantity.value));

async function removeCartItem() {
  isLoading.value = true;
  await removeItem();
  isLoading.value = false;

  const [item] = await lineItemsToTrackingItems([cartItem.value]);
  gtm?.push({ ecommerce: null });
  gtm?.push({
    event: "remove_from_cart",
    ecommerce: {
      currency: useShopCurrency(),
      value: item?.price || NaN,
      items: [item],
    },
  });
}
</script>

<template>
  <div
    v-if="isProduct"
    class="bg-white flex flex-col sm:flex-row items-end sm:items-start px-6 py-4 gap-x-5 rd b-1 b-scheppach-primary-700 w-full"
  >
    <div
      class="flex flex-col sm:flex-row justify-between w-full gap-5 sm:gap-10 order-2 sm:order-1"
    >
      <NuxtLink
        :to="productPath"
        class="flex-shrink-0 flex items-center justify-center"
      >
        <SharedMediaImage
          :media="cartItem.cover"
          :alt="`${cartItem.label || cartItem.payload.name || ''} ${t('cart.itemsInCart')}`"
          class="size-50 sm:size-30 object-contain"
        />
      </NuxtLink>

      <div class="w-full">
        <p class="font-bold text-4 mb-5 text-left">
          {{ cartItem.label }}
        </p>

        <div class="flex items-center justify-between">
          <input
            v-model="quantity"
            :type="inputType"
            :disabled="isLoading"
            :min="(cartItem as any).quantityInformation?.minPurchase || 1"
            :max="(cartItem as any).quantityInformation?.maxPurchase || maxQty"
            :step="(cartItem as any).quantityInformation?.purchaseSteps || 1"
            class="bg-white w-15 h-7 rd b-1 b-scheppach-primary-500 text-center text-md c-scheppach-primary-500"
            :aria-label="t('cart.itemsInCart')"
            @focus="inputFocus"
            @blur="inputBlur"
          />

          <div
            v-if="quantity == itemQuantity"
            class="price flex flex-row gap-3 sm:gap-0 sm:flex-col items-center flex-wrap overflow-x-visible overflow-y-hidden"
          >
            <SharedPrice
              :value="displayPrice"
              class="text-5 font-700 c-scheppach-primary-500"
            >
              <template #afterPrice>*</template>
            </SharedPrice>
            <SharedPrice
              v-if="itemSpecialPrice && itemQuantity > 0"
              :value="itemRegularPrice * itemQuantity"
              class="text-sm c-scheppach-neutral-500 custom-line-through"
            >
              <template #afterPrice>*</template>
            </SharedPrice>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="size-5 i-sl-bin-1 c-scheppach-primary-700 hover:c-scheppach-error-500 order-1 sm:order-2"
      :class="[isLoading ? 'hidden' : '']"
      @click="removeCartItem"
    >
      {{ $t("checkout.items.removeButton") }}
    </button>
  </div>
  <div v-else class="flex flex-1 flex-col">
    <p v-if="itemOptions" class="mt-1 text-sm text-secondary-500">
      <span v-for="option in itemOptions" :key="option.group" class="mr-2">
        {{ option.group }}: {{ option.option }}
      </span>
    </p>
  </div>
</template>
